import { v4 as uuidv4 } from 'uuid';

export const uuid = (): string => {
  return uuidv4();
};

export const stringToColor = (str: string) => {
  const hash = Array.from(str).reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
  const h = ((hash % 360) % 300) + 20;
  const s = 85 + (hash % 10) + '%';
  const l = 33 + (hash % 5) + '%';
  return `hsl(${h}, ${s}, ${l})`;
};

export function throttle<T extends (...args: any[]) => any, D>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  let lastArgs: Parameters<T> | undefined;

  return function throttled(this: D, ...args: Parameters<T>): void {
    if (timeoutId === undefined) {
      func.apply(this, args);
      timeoutId = setTimeout(() => {
        timeoutId = undefined;
        if (lastArgs !== undefined) {
          throttled.apply(this, lastArgs);
          lastArgs = undefined;
        }
      }, wait);
    } else {
      lastArgs = args;
    }
  };
}

export function debounce<T extends (...args: any[]) => any, D>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function debounced(this: D, ...args: Parameters<T>): void {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}
